import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {
    AddMeToTheFamily,
    CancelCreateFamily,
    CancelDeleteFamily,
    CancelDeleteTravelerInTheFamily,
    CancelEditFamily,
    CancelFamilyCreatingEditingTravelerProfile,
    CreateFamily,
    CreateFamilyTravelerProfile,
    DeleteFamily,
    DeleteTravelerInTheFamily,
    EditFamily,
    EditFamilyTravelerProfile,
    GetFamily,
    GetFamilyTraveler,
    GoToAddTravelerToTheFamily,
    GoToCreateFamily,
    GoToDeleteFamily,
    GoToDeleteTravelerInTheFamily,
    GoToEditFamily,
    GoToEditTravelerInTheFamily,
    ResetFamilyForm,
    ResetFamilyTravelerForm,
} from './family.actions';
import {PartyService} from 'src/app/modules/party/party.service';
import {IParty} from 'src/app/interfaces/general/profile-definitions/party.interface';
import {ITraveler} from 'src/app/interfaces/general/profile-definitions/traveler.interface';
import {ResetForm, UpdateFormValue} from '@ngxs/form-plugin';
import {Navigate} from '@ngxs/router-plugin';
import {concatMap, switchMap, toArray} from 'rxjs/operators';
import {firstValueFrom, from} from 'rxjs';
import {IProfile} from 'src/app/interfaces/profile';
import {AppInsightsService} from 'src/app/core/app-insights/app-insights.service';
import {
    errorHandler,
    httpErrorToString,
    IErrorHandlerArgs,
} from 'src/app/shared/helpers/error-handler';
import {ProfileMediaApiService} from 'src/app/shared/services/profile-media-api.service';

// import {mapServerValidationMessages} from 'src/app/shared/helpers/server-validation-messages.helper';

export interface IFamilyState {
    data: IParty;
    dataForm: {
        model: Partial<IParty>;
    };
    person: ITraveler;
    personForm: {
        model: ITraveler;
    };
    loading: boolean;
    hasValue: boolean;
    error: any;
}

const EMPTY_TRAVELER: ITraveler = {
    id: '',
    identifier: '',
    salutation: '',
    nationality: '',
    birthDate: null,
    familyName: '',
    givenName: '',
    passport: '',
    additionalProperty: null,
    reduction: 'none',
    gender: '',
    country: '',
    email: '',
    telephone: '',
    profileImage: '',
    supportingDocument: [],
};

@State<IFamilyState>({
    name: 'family',
    defaults: {
        data: null,
        dataForm: {
            model: {
                name: '',
                member: [],
            },
        },
        person: null,
        personForm: {
            model: {...EMPTY_TRAVELER},
        },
        loading: false,
        hasValue: false,
        error: null,
    },
})
@Injectable()
export class FamilyState {
    private readonly _errorHandlerArgsInit: IErrorHandlerArgs = {
        error: null,
        appInsightsSrv: this.insights,
        scope: 'FamilyState'
    };
    constructor(
        private partyService: PartyService,
        private store: Store,
        private insights: AppInsightsService,
        private profileMediaApi: ProfileMediaApiService,
    ) { }

    @Selector()
    public static state(state: IFamilyState): IFamilyState {
        return state;
    }

    @Selector()
    public static family(state: IFamilyState): IParty {
        return state.data;
    }

    @Selector()
    public static person(state: IFamilyState): ITraveler {
        return state.person;
    }

    @Selector()
    public static loading(state: IFamilyState): boolean {
        return state.loading;
    }

    @Selector()
    public static hasValue(state: IFamilyState): boolean {
        return state.hasValue;
    }

    @Selector()
    public static error(state: IFamilyState): any {
        return state.error;
    }

    @Action(ResetFamilyForm)
    public resetFamilyForm(): void {
        this.store.dispatch(new ResetForm({path: 'family.dataForm'}));
    }

    @Action(ResetFamilyTravelerForm)
    public resetFamilyTravelerForm(): void {
        this.store.dispatch(new ResetForm({path: 'family.personForm'}));
    }

    @Action(GetFamily)
    public async getFamily(ctx: StateContext<IFamilyState>): Promise<IFamilyState> {
        ctx.patchState({loading: true, error: null});
        try {
            const family = await firstValueFrom(this.partyService.getFamily());
            this.store.dispatch(
                new UpdateFormValue({
                    path: 'family.dataForm',
                    value: family,
                })
            );
            ctx.patchState({
                data: family,
                hasValue: !!family,
                loading: false,
                error: null,
            });
        } catch (error) {
            errorHandler({...this._errorHandlerArgsInit, error});
            ctx.patchState({
                loading: false,
                error: httpErrorToString(error),
            });
        }
        return ctx.getState();
    }

    @Action(GetFamilyTraveler)
    public async getFamilyTraveler(ctx: StateContext<IFamilyState>, {travelerId}: GetFamilyTraveler): Promise<IFamilyState> {
        ctx.patchState({loading: true, error: null});
        try {
            const family = await firstValueFrom(this.partyService.getFamily());
            const traveler = family?.member?.find((member) => member?.identifier === travelerId);
            ctx.patchState({
                person: traveler,
                personForm: {
                    model: mapMemberToFormRepresentation(traveler),
                },
                loading: false,
                error: null,
            });
        } catch (error) {
            errorHandler({...this._errorHandlerArgsInit, error});
            ctx.patchState({
                loading: false,
                error: httpErrorToString(error),
            });
        }
        return ctx.getState();
    }

    @Action(GoToCreateFamily)
    public goToCreateFamily(): void {
        this.store.dispatch(new Navigate([`/party/family/create`]));
    }

    @Action(CreateFamily)
    public async createFamily(ctx: StateContext<IFamilyState>, {settings}: CreateFamily): Promise<IFamilyState> {
        ctx.patchState({loading: true, error: null});
        const {addMe} = settings;
        let family: IParty | undefined;
        try {
            const result: Partial<IParty> = {name: 'family', additionalType: 'family'};
            family = await firstValueFrom(this.partyService.create(result));
            ctx.patchState({
                data: family,
                hasValue: !!family,
                loading: false,
                error: null,
            });
        } catch (error) {
            errorHandler({...this._errorHandlerArgsInit, error});
            ctx.patchState({
                loading: false,
                error: httpErrorToString(error),
            });
        }
        if (addMe && family) {
            this.store.dispatch(new AddMeToTheFamily());
        } else {
            this.store.dispatch(new Navigate([`/party/family`]));
        }
        return ctx.getState();
    }

    @Action(GoToEditFamily)
    public goToEditFamily(): void {
        this.store.dispatch(new Navigate([`/party/family/edit`]));
    }

    @Action(EditFamily)
    public async editFamily(ctx: StateContext<IFamilyState>): Promise<IFamilyState> {
        ctx.patchState({loading: true, error: null});
        try {
            const partyFormModel = ctx.getState().dataForm.model;
            const family: IParty = await firstValueFrom(this.partyService.getFamily());
            ctx.patchState({
                data: family,
                hasValue: !!family,
                loading: false,
                error: null,
            });
            const forSave = {...family, ...partyFormModel};
            await firstValueFrom(this.partyService.update(forSave));
            this.store.dispatch(new Navigate([`/party/family`]));
        } catch (error) {
            errorHandler({...this._errorHandlerArgsInit, error});
            ctx.patchState({
                loading: false,
                error,
            });
        }
        return ctx.getState();
    }

    @Action([CancelCreateFamily, CancelEditFamily, CancelDeleteFamily])
    public cancelActionFamily(): void {
        this.store.dispatch(new Navigate([`/party/family`]));
    }

    @Action(GoToDeleteFamily)
    public goToDeleteFamily(): void {
        this.store.dispatch(new Navigate([`/party/family/delete`]));
    }

    @Action(DeleteFamily)
    public async deleteFamily(ctx: StateContext<IFamilyState>): Promise<IFamilyState> {
        const family = ctx.getState().data;

        if (!family) {
            ctx.patchState({
                loading: false,
                error: 'No family to delete',
            });
            return ctx.getState();
        }

        try {
            ctx.patchState({loading: true, error: null});
            await firstValueFrom(this.partyService.delete(family.identifier));
            ctx.patchState({
                loading: false,
                data: null,
            });
            this.store.dispatch(new Navigate([`/party/family`]));
        } catch (error) {
            errorHandler({...this._errorHandlerArgsInit, error});
            ctx.patchState({
                loading: false,
                error: httpErrorToString(error),
            });
        }
        return ctx.getState();
    }

    @Action(GoToAddTravelerToTheFamily)
    public addTravelerToTheFamily(ctx: StateContext<IFamilyState>): void {
        ctx.patchState({
            person: {...EMPTY_TRAVELER},
            personForm: {
                model: {...EMPTY_TRAVELER},
            },
        });
        this.store.dispatch(new Navigate([`/party/family/traveler/add`]));
    }

    @Action(GoToEditTravelerInTheFamily)
    public goToEditTravelerInTheFamily(ctx: StateContext<IFamilyState>, {traveler}: GoToEditTravelerInTheFamily): void {
        ctx.patchState({
            person: traveler,
            personForm: {
                model: mapMemberToFormRepresentation(traveler),
            },
        });
        const travelerId = traveler.identifier;
        this.store.dispatch(new Navigate([`/party/family/traveler/${travelerId}/edit`]));
    }

    @Action(GoToDeleteTravelerInTheFamily)
    public goToDeleteTravelerInTheFamily(ctx: StateContext<IFamilyState>, {travelerId}: GoToDeleteTravelerInTheFamily): void {
        ctx.patchState({
            person: null,
            personForm: {
                model: {...EMPTY_TRAVELER},
            },
        });
        this.store.dispatch(new Navigate([`/party/family/traveler/${travelerId}/delete`]));
    }

    @Action(DeleteTravelerInTheFamily)
    public async deleteTravelerInTheFamily(
        ctx: StateContext<IFamilyState>,
        {travelerId}: DeleteTravelerInTheFamily
    ): Promise<IFamilyState> {
        const family = ctx.getState().data ?? (await firstValueFrom(this.partyService.getFamily()));

        const partyId = family?.identifier;

        try {
            ctx.patchState({loading: true, error: null});
            // if the family is readonly - we delete reference from traveler to the party
            if (family.readonly) {
                await firstValueFrom(this.partyService.delete(partyId));
                ctx.patchState({
                    data: null,
                    hasValue: false,
                    loading: false,
                    person: null,
                });
            } else {
                const updatedFamily = await firstValueFrom(this.partyService.travelerDelete(travelerId, partyId));
                ctx.patchState({
                    data: updatedFamily,
                    loading: false,
                    person: null,
                });
            }
            this.store.dispatch(new Navigate([`/party/family`]));
        } catch (error) {
            errorHandler({...this._errorHandlerArgsInit, error});
            ctx.patchState({
                loading: false,
                error: httpErrorToString(error),
            });
        }
        return ctx.getState();
    }

    @Action([
        CancelDeleteTravelerInTheFamily,
        CancelFamilyCreatingEditingTravelerProfile
    ])
    public cancelDeleteTravelerInTheFamily(): void {
        this.store.dispatch(new Navigate([`/party/family`]));
    }

    @Action(CreateFamilyTravelerProfile)
    public async createFamilyTravelerProfile(ctx: StateContext<IFamilyState>): Promise<IFamilyState> {
        ctx.patchState({loading: true, error: null});
        const family = ctx.getState().data ?? (await firstValueFrom(this.partyService.getFamily()));
        const model = {...ctx.getState().personForm.model};
        const newProfileImage = model.profileImage;

        try {
            // supportingDocs for upload
            const forUpload: {name, file}[] = (model.supportingDocument as any)?.filter((d) => !!d && typeof d === 'object') ?? [];
            const uploadExec$ = from(forUpload).pipe(
                concatMap(({name, file}) => this.profileMediaApi.uploadSupportingDocument({name, file}, () => { })),
                toArray(),
            );
            const uploadedSupportingDocs = await firstValueFrom(uploadExec$);
            const uploadedIdentifiers = uploadedSupportingDocs.map((d) => d.identifier).filter(Boolean);

            // profileImage for upload if we get an object from the form input
            // it means that the user has uploaded a new profile picture
            if (newProfileImage && typeof newProfileImage === 'object' && (newProfileImage as any).file) {
                const file = (newProfileImage as any).file;
                const profileImage = await firstValueFrom(this.profileMediaApi.uploadTravelerPicture({file}, () => { }));
                model.profileImage = profileImage.identifier;
            }

            const updatedTraveler: ITraveler = {
                ...model,
                supportingDocument: uploadedIdentifiers,
            };
            await firstValueFrom(this.partyService.travelerCreate(updatedTraveler, family.identifier));

            this.store.dispatch(
                new ResetForm({
                    path: 'family.personForm',
                })
            );
            ctx.patchState({loading: false});
            this.store.dispatch(new Navigate([`/party/family`]));
        } catch (error) {
            errorHandler({...this._errorHandlerArgsInit, error});
            ctx.patchState({
                loading: false,
                error: httpErrorToString(error),
            });
        }
        return ctx.getState();
    }

    @Action(EditFamilyTravelerProfile)
    public async editFamilyTravelerProfile(ctx: StateContext<IFamilyState>): Promise<IFamilyState> {
        ctx.patchState({loading: true, error: null});
        const family = ctx.getState().data ?? (await firstValueFrom(this.partyService.getFamily()));
        const currentPersonState = ctx.getState().person;
        const model = {...ctx.getState().personForm.model};
        const newProfileImage = model.profileImage;
        const currentProfileImage = ctx.getState().person.profileImage;

        try {
            // supportingDocs for upload
            const forUpload: {name, file}[] = (model.supportingDocument as any)?.filter((d) => !!d && typeof d === 'object') ?? [];
            const uploadExec$ = from(forUpload).pipe(
                concatMap(({name, file}) => this.profileMediaApi.uploadSupportingDocument({name, file}, () => { })),
                toArray(),
            );
            const uploadedSupportingDocs = await firstValueFrom(uploadExec$);
            const uploadedIdentifiers = uploadedSupportingDocs.map((d) => d.identifier).filter(Boolean);

            // profileImage for upload if we get an object from the form input
            // it means that the user has uploaded a new profile picture
            if (newProfileImage && typeof newProfileImage === 'object' && (newProfileImage as any).file) {
                const file = (newProfileImage as any).file;
                const profileImage = await firstValueFrom(this.profileMediaApi.uploadTravelerPicture({file}, () => { }));
                model.profileImage = profileImage.identifier;
            }

            // supportingDocs for delete
            const supportingDocs = ctx.getState().person.supportingDocument ?? [];
            const supportingDocsForm = new Set(ctx.getState().personForm.model.supportingDocument?.filter((d) => typeof d === 'string') ?? []);

            // we are updating the travelers before removing the supporting docs from the server
            // in case of an error we will have the supporting docs on the server
            const updatedSupportingDocs = [...supportingDocsForm, ...uploadedIdentifiers];
            const updatedTraveler: ITraveler = {
                ...currentPersonState,
                ...model,
                supportingDocument: updatedSupportingDocs,
            };
            await firstValueFrom(this.partyService.travelerUpdate(updatedTraveler, family.identifier));

            // delete profile picture from the server if it is not the same as the new one
            if (currentProfileImage && currentProfileImage !== newProfileImage) {
                await firstValueFrom(this.profileMediaApi.delete(currentProfileImage));
            }

            // delete supporting docs from the server
            const supportDocsForDelete = [...supportingDocs].filter((x) => !supportingDocsForm.has(x));
            await firstValueFrom(this.profileMediaApi.delete(supportDocsForDelete));

            this.store.dispatch(
                new ResetForm({
                    path: 'family.personForm',
                })
            );
            ctx.patchState({loading: false});
            this.store.dispatch(new Navigate([`/party/family`]));
        } catch (error) {
            errorHandler({...this._errorHandlerArgsInit, error});
            ctx.patchState({
                loading: false,
                error: httpErrorToString(error),
            });
        }
        return ctx.getState();
    }

    @Action(AddMeToTheFamily)
    public async addMeToTheFamily(ctx: StateContext<IFamilyState>): Promise<IFamilyState> {
        ctx.patchState({loading: true, error: null});
        try {
            const family = await firstValueFrom(this.partyService.getFamily());
            const updatedFamily = await firstValueFrom(this.partyService.inviteToParty(family.identifier)
              .pipe(
                switchMap((res) => this.partyService.joinParty(res.inviteToken))
              )
            );
            ctx.patchState({
                data: updatedFamily,
                hasValue: !!updatedFamily,
                loading: false,
                error: null,
            });
            this.store.dispatch(new Navigate([`/party/family`]));
        } catch (error) {
            errorHandler({...this._errorHandlerArgsInit, error});
            ctx.patchState({
                loading: false,
                error: httpErrorToString(error),
            });
        }
        return ctx.getState();
    }
}

const profileToTraveler = (profile: Partial<IProfile>): ITraveler => {
    return {
        salutation: profile.salutation,
        givenName: profile.givenName,
        familyName: profile.familyName,
        birthDate: profile.birthDate as Date,
        nationality: profile.nationality,
        reduction: profile.reduction,
        passport: profile.passport,
        additionalProperty: undefined,
        country: undefined,
        email: undefined,
        gender: undefined,
        id: undefined,
        identifier: profile.profileId,
        telephone: undefined,
        profileImage: profile.profileImage,
        supportingDocument: undefined,
    };
};

const mapMemberToFormRepresentation = (member: ITraveler): ITraveler => {
    const memberSalutation = member?.salutation
        ? member.salutation[0].toUpperCase() + member.salutation.slice(1)
        : member?.salutation;
    return {
        ...member,
        salutation: memberSalutation,
    };
};
