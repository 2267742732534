import {concatMap, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {IParty} from 'src/app/interfaces/general/profile-definitions/party.interface';
import {HttpClient, HttpEvent, HttpResponse} from '@angular/common/http';
import {ConfigurationService} from 'src/app/core/config/configuration.service';
import {ITraveler} from 'src/app/interfaces/general/profile-definitions/traveler.interface';
import {uploadProgress} from 'src/app/shared/helpers/upload-progress';
import {IProfileImg} from 'src/app/interfaces/general/profile-definitions/profile-img.interface';
import {InviteToPartyResponse} from 'src/app/interfaces/general/responses/invite-to-party-response.interface';

@Injectable({
    providedIn: 'root',
})
export class PartyService {
    private readonly _partyBaseUrl: string;
    private readonly _profileMediaUrl: string;

    constructor(
        private http: HttpClient,
        private config: ConfigurationService,
    ) {
        this._partyBaseUrl = this.config.get('profileApiUrl') + '/parties';
        this._profileMediaUrl = this.config.get('profileApiUrl') + '/media';
    }

    public getAll(): Observable<IParty[]> {
        return this.http.get<IParty[]>(`${this._partyBaseUrl}`);
    }

    public getAllExceptFamily(): Observable<IParty[]> {
        return this.getAll().pipe(
            map((parties) => {
                // TODO workaround for MVP to filter family party from the list of the parties
                const family = parties?.filter((p) => p.additionalType !== 'family') ?? [];
                return family;
            }),
        );
    }

    public getFamily(): Observable<IParty | null> {
        return this.getAll().pipe(
            map((parties) => {
                // TODO workaround for MVP to distinguish family party
                const family = parties?.find((p) => p.additionalType === 'family') ?? null;
                return family;
            }),
        );
    }

    public getById(id: string): Observable<IParty> {
        return this.http.get<IParty>(`${this._partyBaseUrl}/${id}`);
    }

    public create(party: Partial<IParty>): Observable<IParty> {
        return this.http.post<IParty>(this._partyBaseUrl, party);
    }

    public update(party: IParty): Observable<IParty> {
        return this.http.put<IParty>(`${this._partyBaseUrl}/${party.identifier}`, party);
    }

    public delete(id: string): Observable<any> {
        return this.http.delete<any>(`${this._partyBaseUrl}/${id}`);
    }

    public inviteToParty(partyId: string): Observable<InviteToPartyResponse> {
        return this.http.post<InviteToPartyResponse>(`${this._partyBaseUrl}/${partyId}/invite`, {});
    }

    public joinParty(inviteToken: string): Observable<IParty> {
        return this.http.put<IParty>(`${this._partyBaseUrl}/join`, {
            inviteToken,
        });
    }

    public travelerGetById(travelerId: string, partyId?: string): Observable<ITraveler> {
        return this.getById(partyId).pipe(
            map((party) => {
                // TODO workaround for MVP to distinguish particular user
                const index = party.member.findIndex((t) => t.identifier === travelerId);
                return index === -1 ? null : party.member[index];
            }),
        );
    }

    public travelerCreate(traveler: ITraveler, partyId?: string): Observable<ITraveler> {
        return this.getById(partyId).pipe(
            concatMap((party) => {
                if (Array.isArray(party.member)) {
                    party.member.push(traveler);
                } else {
                    party.member = [traveler];
                }
                return this.update(party);
            }),
            map((party) => party.member[0]),
        );
    }

    public travelerUpdate(traveler: ITraveler, partyId: string): Observable<ITraveler> {
        return this.getById(partyId).pipe(
            concatMap((party) => {
                const index = party.member.findIndex((t) => t.identifier === traveler.identifier);
                if (index !== -1) {
                    party.member[index] = traveler;
                } else {
                    party.member = [traveler];
                }
                return this.update(party);
            }),
            map((party) => {
                const index = party.member.findIndex((t) => t.identifier === traveler.identifier);
                return party.member[index];
            }),
        );
    }

    public travelerDelete(travelerId: string, partyId: string): Observable<IParty> {
        return this.getById(partyId).pipe(
            concatMap((party) => {
                const index = party.member.findIndex((t) => t.identifier === travelerId);
                if (index !== -1) {
                    party.member.splice(index, 1);
                }
                return this.update(party);
            }),
        );
    }

    public travelerUpdatePicture(
        picture: File,
        uploadCallback: (progress: number) => void,
    ): Observable<HttpResponse<IProfileImg> | HttpEvent<IProfileImg>> {
        const formData = new FormData();
        formData.append('file', picture);
        formData.append('name', picture.name);
        formData.append('additionalType', 'TravelerProfileImage');

        return this.http
            .post<IProfileImg>(`${this._profileMediaUrl}`, formData, {
                reportProgress: true,
                observe: 'events',
            })
            .pipe(uploadProgress(uploadCallback));
    }

    public travelerDeletePicture(mediaIdentifier: string): Observable<void> {
        return this.http.delete<void>(`${this._profileMediaUrl}/${mediaIdentifier}`);
    }
}
