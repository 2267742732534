import {ITraveler} from 'src/app/interfaces/general/profile-definitions/traveler.interface';
import {IFamilyCreateSettings} from 'src/app/modules/party/family/family-creator/family-create-settings.interface';

export class ResetFamilyForm {
    public static readonly type = '[Family] Reset family form';
}

export class ResetFamilyTravelerForm {
    public static readonly type = '[Family] Reset family traveler form';
}

export class GetFamily {
    public static readonly type = '[Family] Get family';
}

export class AddMeToTheFamily {
    public static readonly type = '[Family] Add me to the family';
}

export class CreateFamily {
    public static readonly type = '[Family] Create family';
    constructor(public settings: IFamilyCreateSettings) {}
}

export class EditFamily {
    public static readonly type = '[Family] Edit family';
}

export class GoToEditFamily {
    public static readonly type = '[Family] Go to edit family';
}

export class CancelCreateFamily {
    public static readonly type = '[Family] Cancel create family';
}

export class CancelEditFamily {
    public static readonly type = '[Family] Cancel edit family';
}

export class DeleteFamily {
    public static readonly type = '[Family] Delete family';
}

export class CancelDeleteFamily {
    public static readonly type = '[Family] Cancel delete family';
}

export class GoToDeleteFamily {
    public static readonly type = '[Family] Go to delete family';
}

export class GoToCreateFamily {
    public static readonly type = '[Family] Go to create family';
}

export class GetFamilyTraveler {
    public static readonly type = '[Family] Get family traveler';
    constructor(public travelerId: string) {}
}

export class GoToAddTravelerToTheFamily {
    public static readonly type = '[Family] Go to add traveler to the family';
}

export class GoToEditTravelerInTheFamily {
    public static readonly type = '[Family] Go to edit traveler in the family';
    constructor(public traveler: ITraveler) {}
}

export class GoToDeleteTravelerInTheFamily {
    public static readonly type = '[Family] Go to delete traveler in the family';
    constructor(public travelerId: string) {}
}

export class DeleteTravelerInTheFamily {
    public static readonly type = '[Family] Delete traveler in the family';
    constructor(public travelerId: string) {}
}

export class CancelDeleteTravelerInTheFamily {
    public static readonly type = '[Family] Cancel delete traveler in the family';
}

export class EditFamilyTravelerProfile {
    public static readonly type = '[Family] Edit family traveler profile';
}

export class CreateFamilyTravelerProfile {
    public static readonly type = '[Family] Create family traveler profile';
}

export class CancelFamilyCreatingEditingTravelerProfile {
    public static readonly type = '[Family] Cancel family creating editing traveler profile';
}

