import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {IParty} from 'src/app/interfaces/general/profile-definitions/party.interface';
import {PartyService} from 'src/app/modules/party/party.service';
import {GetParties} from 'src/app/store/parties/parties.actions';
import {firstValueFrom} from 'rxjs';
import {errorHandler, IErrorHandlerArgs} from 'src/app/shared/helpers/error-handler';
import {AppInsightsService} from 'src/app/core/app-insights/app-insights.service';

export interface IPartiesState {
    parties: IParty[];
    loading: boolean;
    hasValue: boolean;
    error: any;
}

@State<IPartiesState>({
    name: 'parties',
    defaults: {
        parties: [],
        loading: false,
        hasValue: false,
        error: null,
    },
})
@Injectable()
export class PartiesState {
    private readonly _errorHandlerArgsInit: IErrorHandlerArgs = {
        error: null,
        appInsightsSrv: this.insights,
        scope: 'PartiesState'
    };
    constructor(
        private partyService: PartyService,
        private insights: AppInsightsService,
    ) { }

    @Selector()
    public static parties(state: IPartiesState): any[] {
        return state.parties;
    }

    @Selector()
    public static loading(state: IPartiesState): boolean {
        return state.loading;
    }

    @Selector()
    public static hasValue(state: IPartiesState): boolean {
        return state.hasValue;
    }

    @Action(GetParties)
    public async getParties(ctx: StateContext<IPartiesState>): Promise<any> {
        ctx.patchState({loading: true, error: null});
        try {
            const parties = await firstValueFrom(this.partyService.getAllExceptFamily());
            ctx.patchState({
                parties: parties,
                hasValue: !!parties?.length,
                loading: false,
            });
        } catch (error) {
            errorHandler({...this._errorHandlerArgsInit, error});
            ctx.patchState({
                loading: false,
                error,
            });
        }
    }
}
