import {ITraveler} from 'src/app/interfaces/general/profile-definitions/traveler.interface';

export class ResetPartyForm {
    public static readonly type = '[PartyItem] Reset party form';
}
export class GetPartyItem {
    public static readonly type = '[PartyItem] Get party item';
    constructor(public partyId?: string) {
    }
}

export class CreatePartyItem {
    public static readonly type = '[PartyItem] Create party item';
}

export class EditPartyItem {
    public static readonly type = '[PartyItem] Edit party item';
    constructor(public partyId: string) {
    }
}

export class CancelEditPartyItem {
    public static readonly type = '[PartyItem] Edit party item';
}


export class DeletePartyItem {
    public static readonly type = '[PartyItem] Delete party item';
    constructor(public partyId: string) {
    }
}

export class GoToEditPartyItem {
    public static readonly type = '[PartyItem] Go to edit party item';
    constructor(public partyId: string) {
    }
}

export class GoToDeletePartyItem {
    public static readonly type = '[PartyItem] Go to delete party item';
    constructor(public partyId: string) {
    }
}

export class CreateTravelerProfile {
    public static readonly type = '[PartyItem] Create traveler profile';
    constructor(public partyId: string) {
    }
}

export class AddMe {
    public static readonly type = '[PartyItem] Add me to the party';
    constructor(public partyId: string) {
    }
}

export class EditTravelerProfile {
    public static readonly type = '[PartyItem] Edit traveler profile';
    constructor(public partyId: string) {
    }
}

export class CancelCreatingEditingTravelerProfile {
    public static readonly type = '[PartyItem] Cancel edit traveler profile';
    constructor(public partyId: string) {}
}

export class RemoveTravelerProfile {
    public static readonly type = '[PartyItem] Remove traveler profile';
    constructor(public partyId: string, public travelerId: string) {
    }
}

export class GetTravelerProfile {
    public static readonly type = '[PartyItem] Get traveler profile';
    constructor(public id: string, public travelerId: string) {
    }
}

export class GoToEditTravelerProfile {
    public static readonly type = '[PartyItem] Go to edit traveler profile';
    constructor(public traveler: ITraveler, public partyId: string) {
    }
}

export class GoToCreateTravelerProfile {
    public static readonly type = '[PartyItem] Go to create traveler profile';
    constructor(public partyId: string) {
    }
}

export class GoToDeleteTravelerProfile {
    public static readonly type = '[PartyItem] Go to delete traveler profile';
    constructor(public travelerId: string, public partyId: string) {
    }
}
