import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {
    AddMe,
    CancelCreatingEditingTravelerProfile,
    CancelEditPartyItem,
    CreatePartyItem,
    CreateTravelerProfile,
    DeletePartyItem,
    EditPartyItem,
    EditTravelerProfile,
    GetPartyItem,
    GetTravelerProfile,
    GoToCreateTravelerProfile,
    GoToDeletePartyItem,
    GoToDeleteTravelerProfile,
    GoToEditPartyItem,
    GoToEditTravelerProfile,
    RemoveTravelerProfile,
    ResetPartyForm,
} from './party-item.actions';
import {PartyService} from 'src/app/modules/party/party.service';
import {IParty} from 'src/app/interfaces/general/profile-definitions/party.interface';
import {ITraveler} from 'src/app/interfaces/general/profile-definitions/traveler.interface';
import {ResetForm, UpdateFormValue} from '@ngxs/form-plugin';
import {Navigate} from '@ngxs/router-plugin';
import {concatMap, switchMap, toArray} from 'rxjs/operators';
import {firstValueFrom, from} from 'rxjs';
import {IProfile} from 'src/app/interfaces/profile';
import {AppInsightsService} from 'src/app/core/app-insights/app-insights.service';
import {
    errorHandler,
    httpErrorToString,
    IErrorHandlerArgs,
} from 'src/app/shared/helpers/error-handler';
import {ProfileMediaApiService} from 'src/app/shared/services/profile-media-api.service';
import {mapServerValidationMessages} from 'src/app/shared/helpers/server-validation-messages.helper';
import {isArray} from 'lodash';

export interface IPartyItemState {
    party: IParty;
    partyForm: {
        model: Partial<IParty>;
    };
    person: ITraveler;
    personForm: {
        model: ITraveler;
    };
    loading: boolean;
    hasValue: boolean;
    backendFormValidationError: any;
    error: any;
}

const EMPTY_TRAVELER: ITraveler = {
    id: '',
    identifier: '',
    salutation: '',
    nationality: '',
    birthDate: null,
    familyName: '',
    givenName: '',
    passport: '',
    additionalProperty: null,
    reduction: 'none',
    gender: '',
    country: '',
    email: '',
    telephone: '',
    profileImage: '',
    supportingDocument: [],
};

@State<IPartyItemState>({
    name: 'partyItem',
    defaults: {
        party: null,
        partyForm: {
            model: {
                name: '',
                member: [],
            },
        },
        person: null,
        personForm: {
            model: {...EMPTY_TRAVELER},
        },
        loading: false,
        hasValue: false,
        backendFormValidationError: null,
        error: null,
    },
})
@Injectable()
export class PartyItemState {
    private readonly _errorHandlerArgsInit: IErrorHandlerArgs = {
        error: null,
        appInsightsSrv: this.insights,
        scope: 'PartyItemState'
    };
    constructor(
        private partyService: PartyService,
        private store: Store,
        private insights: AppInsightsService,
        private profileMediaApi: ProfileMediaApiService,
    ) { }

    @Selector()
    public static state(state: IPartyItemState): IPartyItemState {
        return state;
    }

    @Selector()
    public static party(state: IPartyItemState): IParty {
        return state.party;
    }

    @Selector()
    public static person(state: IPartyItemState): ITraveler {
        return state.person;
    }

    @Selector()
    public static loading(state: IPartyItemState): boolean {
        return state.loading;
    }

    @Selector()
    public static hasValue(state: IPartyItemState): boolean {
        return state.hasValue;
    }

    @Selector()
    public static error(state: IPartyItemState): any {
        return state.error;
    }

    @Selector()
    public static backendFormValidationError(state: IPartyItemState): any {
        return state.backendFormValidationError;
    }

    @Selector()
    public static personSupportingDocuments(state: IPartyItemState): string[] {
        return state?.person?.supportingDocument ?? [];
    }

    @Selector()
    public static personProfilePicture(state: IPartyItemState): string | undefined {
        return state?.person?.profileImage;
    }

    @Action(ResetPartyForm)
    public resetPartyForm(): void {
        this.store.dispatch(new ResetForm({path: 'partyItem.partyForm'}));
    }

    @Action(GetPartyItem)
    public async getParty(ctx: StateContext<IPartyItemState>, {partyId}: GetPartyItem): Promise<IPartyItemState> {
        ctx.patchState({loading: true, backendFormValidationError: null, error: null});
        try {
            const party: any = await firstValueFrom(this.partyService.getById(partyId));
            this.store.dispatch(
                new UpdateFormValue({
                    path: 'partyItem.partyForm',
                    value: party,
                })
            );
            ctx.patchState({
                party,
                hasValue: !!party,
                loading: false,
                backendFormValidationError: null,
                error: null,
            });
        } catch (error) {
            errorHandler({...this._errorHandlerArgsInit, error});
            ctx.patchState({
                party: null,
                hasValue: false,
                loading: false,
                error,
            });
        }
        return ctx.getState();
    }

    @Action(CreatePartyItem)
    public async createParty(ctx: StateContext<IPartyItemState>): Promise<IPartyItemState> {
        ctx.patchState({loading: true, backendFormValidationError: null, error: null});

        let party: IParty | undefined;
        const partyFormModel = ctx.getState().partyForm.model;
        try {
            const result: Partial<IParty> = {...partyFormModel};
            party = await firstValueFrom(this.partyService.create(result));
            ctx.patchState({
                party,
                hasValue: !!party,
                loading: false,
                backendFormValidationError: null,
                error: null,
            });
            this.store.dispatch(new Navigate([`/party/${party.identifier}`]));
        } catch (error) {
            errorHandler({...this._errorHandlerArgsInit, error});
            ctx.patchState({
                party: null,
                hasValue: false,
                loading: false,
                error,
            });
        }
        const addMe = partyFormModel['addMeAsTraveler'];
        if (party?.identifier && addMe) {
            this.store.dispatch(new AddMe(party.identifier))
        }
        return ctx.getState();
    }

    @Action(EditPartyItem)
    public async editParty(ctx: StateContext<IPartyItemState>, {partyId}: EditPartyItem): Promise<IPartyItemState> {
        ctx.patchState({loading: true, backendFormValidationError: null, error: null});
        try {
            const partyFormModel = ctx.getState().partyForm.model;
            const party: IParty = await firstValueFrom(this.partyService.getById(partyId));
            ctx.patchState({
                party,
                hasValue: !!party,
                loading: false,
                backendFormValidationError: null,
                error: null,
            });
            const forSave = {...party, ...partyFormModel};
            await firstValueFrom(this.partyService.update(forSave));
            this.store.dispatch(new Navigate([`/party/${party.identifier}`]));
        } catch (error) {
            errorHandler({...this._errorHandlerArgsInit, error});
            ctx.patchState({
                party: null,
                hasValue: false,
                loading: false,
                error,
            });
        }
        return ctx.getState();
    }

    @Action(CancelEditPartyItem)
    public cancelEditParty(ctx: StateContext<IPartyItemState>): void {
        ctx.dispatch(new ResetPartyForm());
        this.store.dispatch(new Navigate(['..']));
    }

    @Action(DeletePartyItem)
    public async deleteParty(
        ctx: StateContext<IPartyItemState>,
        {partyId}: EditPartyItem
    ): Promise<IPartyItemState> {
        try {
            ctx.patchState({loading: true, backendFormValidationError: null, error: null});
            await firstValueFrom(this.partyService.delete(partyId));
            ctx.patchState({
                loading: false,
                party: null,
            });
            this.store.dispatch(
                new ResetForm({
                    path: 'partyItem.partyForm',
                })
            );
            this.store.dispatch(
                new ResetForm({
                    path: 'partyItem.personForm',
                })
            );
            this.store.dispatch(new Navigate([`/party`]));
        } catch (error) {
            errorHandler({...this._errorHandlerArgsInit, error});
            ctx.patchState({
                loading: false,
                error: httpErrorToString(error),
            });
        }
        return ctx.getState();
    }

    @Action(GoToEditPartyItem)
    public goToEditParty(
        ctx: StateContext<IPartyItemState>,
        {partyId}: GoToEditPartyItem
    ): void {
        this.store.dispatch(new Navigate([`/party/${partyId}/edit`]));
    }

    @Action(GoToDeletePartyItem)
    public goToDeleteParty(
        ctx: StateContext<IPartyItemState>,
        {partyId}: GoToDeletePartyItem
    ): void {
        this.store.dispatch(new Navigate([`/party/${partyId}/delete`]));
    }

    @Action(GetTravelerProfile)
    public async getTravelerProfile(
        ctx: StateContext<IPartyItemState>,
        {id, travelerId}: GetTravelerProfile
    ): Promise<any> {
        ctx.patchState({loading: true, backendFormValidationError: null, error: null});

        this.store.dispatch(
            new ResetForm({
                path: 'partyItem.personForm',
            })
        );

        try {
            const party: IParty =
                ctx.getState().party || (await firstValueFrom(this.partyService.getById(id)));
            const person: ITraveler = party.member.find(
                (traveler) => traveler.identifier === travelerId
            );

            this.store.dispatch(
                new UpdateFormValue({
                    path: 'partyItem.personForm',
                    value: mapMemberToFormRepresentation(person),
                })
            );

            ctx.patchState({loading: false, person});
        } catch (error) {
            errorHandler({...this._errorHandlerArgsInit, error});
            ctx.patchState({loading: false, error});
        }
    }

    @Action(CreateTravelerProfile)
    public async createTravelerProfile(
        ctx: StateContext<IPartyItemState>,
        {partyId}: EditTravelerProfile
    ): Promise<any> {
        ctx.patchState({loading: true, backendFormValidationError: null, error: null});
        const model = {...ctx.getState().personForm.model};
        const newProfileImage = model.profileImage;

        try {
            // supportingDocs for upload
            const forUpload: {name, file}[] = (ctx.getState().personForm.model.supportingDocument as any)?.filter((d) => !!d && typeof d === 'object') ?? [];
            const uploadExec$ = from(forUpload).pipe(
                concatMap(({name, file}) => this.profileMediaApi.uploadSupportingDocument({name, file}, () => { })),
                toArray(),
            );
            const uploadedSupportingDocs = await firstValueFrom(uploadExec$);
            const uploadedIdentifiers = uploadedSupportingDocs.map((d) => d.identifier).filter(Boolean);

            // profileImage for upload if we get an object from the form input
            // it means that the user has uploaded a new profile picture
            if (newProfileImage && typeof newProfileImage === 'object' && (newProfileImage as any).file) {
                const file = (newProfileImage as any).file;
                const profileImage = await firstValueFrom(this.profileMediaApi.uploadTravelerPicture({file}, () => { }));
                model.profileImage = profileImage.identifier;
            }

            // we are updating the travelers before removing the supporting docs from the server
            // in case of an error we will have the supporting docs on the server
            const updatedTraveler: ITraveler = {
                ...model,
                supportingDocument: uploadedIdentifiers,
            };
            await firstValueFrom(this.partyService.travelerCreate(updatedTraveler, partyId));

            this.store.dispatch(
                new ResetForm({
                    path: 'partyItem.personForm',
                })
            );
            ctx.patchState({loading: false});
            this.store.dispatch(new Navigate([`/party/${partyId}`]));
        } catch (error) {
            errorHandler({...this._errorHandlerArgsInit, error});
            const backendFormValidationError = mapServerValidationMessages(
                error?.error?.map((e) => {
                    return {
                        ...e,
                        memberNames: e?.memberNames.map((memberName) => {
                            return memberName?.split('.')?.length > 1
                                ? memberName?.split('.')[1]
                                : memberName;
                        }),
                    };
                })
            );
            ctx.patchState({
                loading: false,
                error: httpErrorToString(error),
                backendFormValidationError: backendFormValidationError,
            });
        }
    }

    @Action(CancelCreatingEditingTravelerProfile)
    public cancelCreateTravelerProfile(
        ctx: StateContext<IPartyItemState>,
        {partyId}: CancelCreatingEditingTravelerProfile
    ): void {
        this.store.dispatch(new Navigate([`/party/${partyId}`]));
    }

    @Action(AddMe)
    public async addMeToTheParty(
        ctx: StateContext<IPartyItemState>,
        {partyId}: EditTravelerProfile
    ): Promise<IPartyItemState> {
        ctx.patchState({loading: true});
        try {
            const updatedParty = await firstValueFrom(this.partyService
                .inviteToParty(partyId)
                .pipe(switchMap((res) => this.partyService.joinParty(res.inviteToken)))
            );
            ctx.patchState({
                party: updatedParty,
                hasValue: !!updatedParty,
                loading: false,
                error: null,
                backendFormValidationError: null
            });
            this.store.dispatch(new Navigate([`/party/${partyId}`]));
        } catch (error) {
            errorHandler({...this._errorHandlerArgsInit, error});
            ctx.patchState({
                loading: false,
                error: httpErrorToString(error),
            });
        }
        return ctx.getState();
    }

    @Action(EditTravelerProfile)
    public async editTravelerProfile(
        ctx: StateContext<IPartyItemState>,
        {partyId}: EditTravelerProfile
    ): Promise<any> {
        ctx.patchState({loading: true, backendFormValidationError: null, error: null});
        const currentPersonState = ctx.getState().person;
        const model = {...ctx.getState().personForm.model};
        const newProfileImage = model.profileImage;
        const currentProfileImage = ctx.getState().person.profileImage;

        try {
            // supportingDocs for upload
            const forUpload: {name, file}[] = (ctx.getState().personForm.model.supportingDocument as any)?.filter((d) => !!d && typeof d === 'object') ?? [];
            const uploadExec$ = from(forUpload).pipe(
                concatMap(({name, file}) => this.profileMediaApi.uploadSupportingDocument({name, file}, () => { })),
                toArray(),
            );
            const uploadedSupportingDocs = await firstValueFrom(uploadExec$);
            const uploadedIdentifiers = uploadedSupportingDocs.map((d) => d.identifier).filter(Boolean);

            // profileImage for upload if we get an object from the form input
            // it means that the user has uploaded a new profile picture
            if (newProfileImage && typeof newProfileImage === 'object' && (newProfileImage as any).file) {
                const file = (newProfileImage as any).file;
                const profileImage = await firstValueFrom(this.profileMediaApi.uploadTravelerPicture({file}, () => { }));
                model.profileImage = profileImage.identifier;
            }

            // supportingDocs for delete
            const supportingDocs = ctx.getState().person.supportingDocument ?? [];
            const supportingDocsForm = new Set(ctx.getState().personForm.model.supportingDocument?.filter((d) => typeof d === 'string') ?? []);

            // we are updating the travelers before removing the supporting docs from the server
            // in case of an error we will have the supporting docs on the server
            const updatedSupportingDocs = [...supportingDocsForm, ...uploadedIdentifiers];
            const updatedTraveler: ITraveler = {
                ...currentPersonState,
                ...model,
                supportingDocument: updatedSupportingDocs,
            };
            await firstValueFrom(this.partyService.travelerUpdate(updatedTraveler, partyId));

            // delete profile picture from the server if it is not the same as the new one
            if (currentProfileImage && currentProfileImage !== newProfileImage) {
                // if traveler belongs to some order or ticket there will be an error during removing media because it
                // doesn't allow, user shouldn't now about it
                try {
                    await firstValueFrom(this.profileMediaApi.delete(currentProfileImage));
                } catch (err) {
                    console.error(err);
                }
            }

            // delete supporting docs from the server
            const supportDocsForDelete = [...supportingDocs].filter((x) => !supportingDocsForm.has(x));
            try {
                // if traveler belongs to some order or ticket there will be an error during removing media because it
                // doesn't allow, user shouldn't now about it
                await firstValueFrom(this.profileMediaApi.delete(supportDocsForDelete));
            } catch(err) {
                console.error(err);
            }

            this.store.dispatch(
                new ResetForm({
                    path: 'partyItem.personForm',
                })
            );
            ctx.patchState({loading: false});
            this.store.dispatch(new Navigate([`/party/${partyId}`]));
        } catch (error) {
            errorHandler({...this._errorHandlerArgsInit, error});
            const backendFormValidationError = isArray(error.error) ? mapServerValidationMessages(
                error?.error?.map((e) => {
                    return {
                        ...e,
                        memberNames: e?.memberNames.map((memberName) => {
                            return memberName?.split('.')?.length > 1
                                ? memberName?.split('.')[1]
                                : memberName;
                        }),
                    };
                })
            ) : null;
            const stringError = isArray(error.error) ? null : error.error;
            ctx.patchState({
                loading: false,
                error: stringError,
                backendFormValidationError: backendFormValidationError,
            });
        }
    }

    @Action(RemoveTravelerProfile)
    public async removeTravelerProfile(
        ctx: StateContext<IPartyItemState>,
        {partyId, travelerId}: RemoveTravelerProfile
    ): Promise<any> {
        ctx.patchState({loading: true, backendFormValidationError: null, error: null});
        try {
            const party = ctx.getState().party ?? (await firstValueFrom(this.partyService.getById(partyId)));
            // if the party is readonly - we delete reference from traveler to the party
            if (party.readonly) {
                await firstValueFrom(this.partyService.delete(partyId));
                this.store.dispatch(new Navigate([`/party`]));
            } else {
                await firstValueFrom(this.partyService.travelerDelete(travelerId, partyId));
                this.store.dispatch(new Navigate([`/party/${partyId}`]));
            }
            ctx.patchState({loading: false, party: null});
        } catch (error) {
            errorHandler({...this._errorHandlerArgsInit, error});
            ctx.patchState({loading: false, error});
        }
    }

    @Action(GoToEditTravelerProfile)
    public goToEditTravelerProfile(
        ctx: StateContext<IPartyItemState>,
        {traveler, partyId}: GoToEditTravelerProfile
    ): void {

        this.store.dispatch(
            new UpdateFormValue({
                path: 'partyItem.personForm',
                value: mapMemberToFormRepresentation(traveler),
            })
        );
        const travelerId = traveler.identifier;
        this.store.dispatch(new Navigate([`/party/${partyId}/traveler/${travelerId}/edit`]));
    }

    @Action(GoToCreateTravelerProfile)
    public goToCreateTravelerProfile(
        ctx: StateContext<IPartyItemState>,
        {partyId}: GoToCreateTravelerProfile
    ): void {
        ctx.patchState({person: null});
        this.store.dispatch(
            new UpdateFormValue({
                path: 'partyItem.personForm',
                value: {...EMPTY_TRAVELER},
            })
        );
        this.store.dispatch(new Navigate([`/party/${partyId}/traveler/add`]));
    }

    @Action(GoToDeleteTravelerProfile)
    public goToDeleteTravelerProfile(
        ctx: StateContext<IPartyItemState>,
        {travelerId, partyId}: GoToDeleteTravelerProfile
    ): void {
        this.store.dispatch(new Navigate([`/party/${partyId}/traveler/${travelerId}/delete`]));
    }
}

const profileToTraveler = (profile: Partial<IProfile>): ITraveler => {
    return {
        salutation: profile.salutation,
        givenName: profile.givenName,
        familyName: profile.familyName,
        birthDate: profile.birthDate as Date,
        nationality: profile.nationality,
        reduction: profile.reduction,
        passport: profile.passport,
        additionalProperty: undefined,
        country: undefined,
        email: undefined,
        gender: undefined,
        id: undefined,
        identifier: profile.profileId,
        telephone: undefined,
        profileImage: profile.profileImage,
        supportingDocument: undefined,
    };
};

const mapMemberToFormRepresentation = (member: ITraveler): ITraveler => {
    const memberSalutation = member?.salutation
        ? member.salutation[0].toUpperCase() + member.salutation.slice(1)
        : member?.salutation;
    return {
        ...member,
        salutation: memberSalutation,
    };
};
